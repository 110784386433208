import React from "react";
import { Box, Container, Typography, Grid, Link, Divider } from "@mui/material";
import { Icon } from "@iconify/react";
import "../Footer/Footer.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import CareerModel from "../../common/CareerModel/CareerModel";

export default function Footer({ index }) {
  const FooterLogo = require("../../assets/site-footer-logo.png");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <section>
        <CareerModel opened={open} handleClose={handleClose} />

        <Box sx={{ backgroundColor: "#0876C1", pt: 8 }}>
          <Container maxWidth="lg">
            <motion.div
              initial={{
                opacity: 0,
                // if odd index card,slide from right instead of left
                y: index % 1 === 0 ? -50 : 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0, // Slide in to its original position
                transition: {
                  duration: 1, // Animation duration
                  ease: "easeOut",
                },
              }}
              viewport={{ once: true }}
            >
              <Box sx={{ pb: 4 }}>
                <NavLink exact to="/">
                  <img src={String(FooterLogo)} alt="FooterLogo" />
                </NavLink>
              </Box>
            </motion.div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <motion.div
                    initial={{
                      opacity: 0,
                      // if odd index card,slide from right instead of left
                      y: index % 1 === 0 ? -50 : 50,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0, // Slide in to its original position
                      transition: {
                        duration: 1, // Animation duration
                        ease: "easeOut",
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <Typography sx={{ color: "#ffffff", pb: 6 }}>
                      Our company has been developing high-quality and reliable
                      software for corporate needs since 2000. We are renowned
                      professionals of software development.
                    </Typography>
                  </motion.div>
                  <Box sx={{ display: "flex" }}>
                    <Grid sx={{ px: 2, pl: 0 }}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          x: index % 1 === 0 ? 50 : -50,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut",
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <Link
                          exact
                          href="https://www.facebook.com/Rajasri-Systems-177222105666599/"
                          target="_blank"
                        >
                          <Icon
                            icon="ri:facebook-fill"
                            width={34}
                            height={34}
                            color="#ffffff"
                          />
                        </Link>
                      </motion.div>
                    </Grid>
                    <Grid sx={{ px: 2 }}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          x: index % 1 === 0 ? 50 : -50,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut",
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <Link
                          exact
                          href="https://twitter.com/rajasrisystems"
                          target="_blank"
                        >
                          <Icon
                            icon="ri:twitter-fill"
                            width={34}
                            height={34}
                            color="#ffffff"
                          />
                        </Link>
                      </motion.div>
                    </Grid>
                    <Grid sx={{ px: 2 }}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          x: index % 1 === 0 ? 50 : -50,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut",
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <Link
                          exact
                          href="https://www.linkedin.com/company/rajasrisystem/"
                          target="_blank"
                        >
                          <Icon
                            icon="ri:linkedin-box-fill"
                            width={34}
                            height={34}
                            color="#ffffff"
                          />
                        </Link>
                      </motion.div>
                    </Grid>
                    <Grid sx={{ px: 2 }}>
                      <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          x: index % 1 === 0 ? 50 : -50,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut",
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <Link
                          exact
                          href="https://www.instagram.com/rajasrisystem"
                          target="_blank"
                        >
                          <Icon
                            icon="ri:instagram-fill"
                            width={34}
                            height={34}
                            color="#ffffff"
                          />
                        </Link>
                      </motion.div>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <motion.div
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? -50 : 50,
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut",
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <Box>
                    <ul className="footerLinks">
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#exploreOurServices"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          Artificial Intelligence
                        </Link>
                      </li>
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#exploreOurServices"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          Cloud Computing
                        </Link>
                      </li>
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#exploreOurServices"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          App Development
                        </Link>
                      </li>
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#exploreOurServices"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          Web Experts
                        </Link>
                      </li>
                    </ul>
                  </Box>
                </motion.div>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <motion.div
                  initial={{
                    opacity: 0,
                    // if odd index card,slide from right instead of left
                    y: index % 1 === 0 ? -50 : 50,
                  }}
                  whileInView={{
                    opacity: 1,
                    y: 0, // Slide in to its original position
                    transition: {
                      duration: 1, // Animation duration
                      ease: "easeOut",
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <Box>
                    <ul className="footerLinks">
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#exploreOurServices"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          What We Do
                        </Link>
                      </li>
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#ourClients"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          Our Clients
                        </Link>
                      </li>
                      <li>
                        <Link
                          exact
                          className="hover-underline-animation"
                          href="#aboutUs"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          About Us
                        </Link>
                      </li>
                      <li onClick={handleClickOpen}>
                        <Link
                          exact
                          className="hover-underline-animation"
                          // href="#contactUs"
                          sx={{
                            color: "#ffffff",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          Career
                        </Link>
                      </li>
                    </ul>
                  </Box>
                </motion.div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box>
                  <ul className="footerLinks">
                    <motion.div
                      initial={{
                        opacity: 0,
                        // if odd index card,slide from right instead of left
                        y: index % 1 === 0 ? -50 : 50,
                      }}
                      whileInView={{
                        opacity: 1,
                        y: 0, // Slide in to its original position
                        transition: {
                          duration: 1, // Animation duration
                          ease: "easeOut",
                        },
                      }}
                      viewport={{ once: true }}
                    >
                      <li>
                        <Typography
                          sx={{
                            fontSize: "1.126rem",
                            fontWeight: "600",
                            color: "#ffffff",
                            pb: 2,
                          }}
                        >
                          Rajasri Systems Pvt Ltd
                        </Typography>
                        <Typography sx={{ color: "#ffffff" }}>
                          #26, First Main Road, Nanganallur Chennai 600061,
                          Tamilnadu India
                        </Typography>
                      </li>
                      <li>
                        <Typography sx={{ color: "#ffffff" }}>
                          +91-44-22242272
                        </Typography>
                      </li>
                      <li>
                        <Link
                          href="mailto:businessdev@rajasri.net"
                          sx={{ color: "#ffffff", textDecoration: "none" }}
                        >
                          businessdev@rajasri.net
                        </Link>
                      </li>
                    </motion.div>
                    <Divider sx={{ borderColor: "#ffffff" }} />
                    <li>
                      <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          y: index % 1 === 0 ? -50 : 50,
                        }}
                        whileInView={{
                          opacity: 1,
                          y: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut",
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.126rem",
                            fontWeight: "600",
                            color: "#ffffff",
                            py: 2,
                          }}
                        >
                          Hermits Co., Ltd.
                        </Typography>
                      </motion.div>
                      <motion.div
                        initial={{
                          opacity: 0,
                          // if odd index card,slide from right instead of left
                          y: index % 1 === 0 ? -50 : 50,
                        }}
                        whileInView={{
                          opacity: 1,
                          y: 0, // Slide in to its original position
                          transition: {
                            duration: 1, // Animation duration
                            ease: "easeOut",
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <Typography sx={{ color: "#ffffff" }}>
                          #107 Yoshimura Building, 1-13-10 Utsukushigaoka, Aoba,
                          Yokohama, Kanagawa 225-0002, JAPAN
                        </Typography>
                      </motion.div>
                    </li>
                  </ul>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ borderColor: "#ffffff", mt: 2 }} />
            <Box
              className="footerBottom"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 2.5,
              }}
            >
              <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? 50 : -50,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut",
                  },
                }}
                viewport={{ once: true }}
              >
                <Typography sx={{ color: "#ffffff" }}>
                  &copy; 2025 All Rights Reserved by Rajasri Systems.
                </Typography>
              </motion.div>
              <motion.div
                initial={{
                  opacity: 0,
                  // if odd index card,slide from right instead of left
                  y: index % 1 === 0 ? -50 : 50,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0, // Slide in to its original position
                  transition: {
                    duration: 1, // Animation duration
                    ease: "easeOut",
                  },
                }}
                viewport={{ once: true }}
              >
                {/* <Box>
                  <NavLink
                    exact
                    className="secondary-footer-menu"
                    to=""
                    sx={{ color: "#ffffff", textDecoration: "none", pr: 4 }}
                  >
                    Terms & Condition
                  </NavLink>
                  <NavLink
                    exact
                    className="secondary-footer-menu"
                    to="/privacy-policy"
                    sx={{ color: "#ffffff", textDecoration: "none" }}
                  >
                    Privacy Policy
                  </NavLink>
                </Box> */}
              </motion.div>
            </Box>
          </Container>
        </Box>
      </section>
    </>
  );
}
