/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from "react";
import { produce } from "immer";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  Grid,
  Link,
  TextField,
  Divider,
} from "@mui/material";
import "../ContactUs/ContactUs.css";
import AllButtonComponent from "../../common/AllButtonComponent/AllButtonComponent";
import ToastMessage from "../../common/ToastMessage/ToastMessage";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";

export default function ContactUs({ index }) {
  const initialState = {
    from: "info@rajasri.net",
    to: "businessdev@rajasri.net",
    password: "18R@j@sri",
    subject: "",
    body: {
      name: "",
      mobileNo: "",
      email: "",
      message: "",
    },
    error: {
      subject: "",
      body: {
        name: "",
        mobileNo: "",
        email: "",
        message: "",
      },
    },
  };

  const [getEmailForm, setEmailForm] = useState(initialState);
  const [isToastOpened, setIsToastOpened] = useState(false);

  const [loading, setLoading] = React.useState(false);

  const [responseMessage, setResponseMessage] = useState("");

  const sendEmail = async (data) => {
    try {
      if (!loading) {
        setLoading(true);

        const response = await axios.post(
          "https://enminvithaigal.click/Rajasri_Email/api/Email/send",
          data
        );

        if (!response) {
          return setLoading(false);
        }

        const responseData = response.data;
        const responseDataMessage = setResponseMessage(responseData.message);

        setEmailForm(initialState);
        setIsToastOpened(true);

        setTimeout(() => {
          setIsToastOpened(false);
        }, 3000);

        setLoading(false);
        return responseDataMessage;
      }
    } catch (error) {
      setResponseMessage("Message sent failed!");
      setEmailForm(initialState);
      setIsToastOpened(true);
      setTimeout(() => {
        setIsToastOpened(false);
      }, 3000);
      setLoading(false);
    }
    console.log(responseMessage);
  };

  const handleClose = () => {
    setIsToastOpened(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setEmailForm((prevState) =>
      produce(prevState, (draft) => {
        draft.body[name] = value;
        draft[name] = value;
        draft.error.body[name] = "";
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (getEmailForm.body.name.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.name = "Please enter a name";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.name = "";
        })
      );
    }
    if (getEmailForm.body.mobileNo.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.mobileNo = "Please enter a mobile number";
        })
      );
      return false;
    } else if (getEmailForm.body.mobileNo.length !== 10) {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.mobileNo = "Please enter a valid mobile number";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.mobileNo = "";
        })
      );
    }

    if (getEmailForm.body.email.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.email = "Please enter an email";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.email = "";
        })
      );
    }

    if (getEmailForm.subject.trim() === "") {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.subject = "Please enter a subject";
        })
      );
      return false;
    } else {
      setEmailForm((prevState) =>
        produce(prevState, (draft) => {
          draft.error.body.job = "";
        })
      );
    }

    const data = {
      from: "info@rajasri.net",
      // to: "businessdev@rajasri.net",
      to: "businessdev@rajasri.net",
      password: "18R@j@sri",
      subject: getEmailForm.subject,
      careerSubject: "",
      body: {
        name: getEmailForm.body.name,
        mobileNo: getEmailForm.body.mobileNo,
        email: getEmailForm.body.email,
        job: "",
        message:
          getEmailForm.body.message !== "" ? getEmailForm.body.message : "",
      },
    };
    sendEmail(data);
  };

  return (
    <>
      <section id="contactUs">
        <Box sx={{ py: 8 }}>
          <Container maxWidth="lg">
            <motion.div
              initial={{
                opacity: 0,
                // if odd index card,slide from right instead of left
                y: index % 1 === 0 ? -50 : 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0, // Slide in to its original position
                transition: {
                  duration: 1, // Animation duration
                  ease: "easeOut",
                },
              }}
              viewport={{ once: true }}
            >
              <Box sx={{ textAlign: "center", pb: 4 }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "800", color: "#383745" }}
                >
                  Contact <span className="sectionTitle">Us</span>
                </Typography>
              </Box>
            </motion.div>
            <motion.div
              initial={{
                opacity: 0,
                // if odd index card,slide from right instead of left
                y: index % 1 === 0 ? -50 : 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0, // Slide in to its original position
                transition: {
                  duration: 1, // Animation duration
                  ease: "easeOut",
                },
              }}
              viewport={{ once: true }}
            >
              <Box className="contactForm" sx={{ textAlign: "center", pb: 4 }}>
                <ToastMessage
                  message={responseMessage}
                  IsOpened={isToastOpened}
                  toastClassName={"contactMessage"}
                  handleClose={handleClose}
                />

                <Typography sx={{ pb: 4, color: "#808080" }}>
                  We're always on the lookout to work with new clients
                </Typography>
                <Box>
                  <TextField
                    fullWidth
                    sx={{ mb: 4 }}
                    label="Name"
                    placeholder="Enter your name"
                    variant="outlined"
                    name="name"
                    value={getEmailForm.body.name}
                    onChange={onInputChange}
                    error={Boolean(getEmailForm.error.body.name)}
                    helperText={getEmailForm.error.body.name}
                  />
                </Box>
                <Box>
                  <TextField
                    className="mobileNo"
                    fullWidth
                    sx={{ mb: 4 }}
                    type="number"
                    label="Mobile number"
                    placeholder="Enter your mobile number"
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0, max: 10 } }}
                    name="mobileNo"
                    value={getEmailForm.body.mobileNo}
                    onChange={onInputChange}
                    error={Boolean(getEmailForm.error.body.mobileNo)}
                    helperText={getEmailForm.error.body.mobileNo}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    sx={{ mb: 4 }}
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    variant="outlined"
                    name="email"
                    value={getEmailForm.body.email}
                    onChange={onInputChange}
                    error={Boolean(getEmailForm.error.body.email)}
                    helperText={getEmailForm.error.body.email}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    sx={{ mb: 4 }}
                    label="Subject"
                    placeholder="Enter your subject"
                    variant="outlined"
                    name="subject"
                    value={getEmailForm.subject}
                    onChange={onInputChange}
                    error={Boolean(getEmailForm.error.subject)}
                    helperText={getEmailForm.error.subject}
                  />
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    sx={{ mb: 4 }}
                    variant="outlined"
                    label="Message"
                    multiline
                    rows={3}
                    placeholder="Enter your message"
                    name="message"
                    value={getEmailForm.body.message}
                    onChange={onInputChange}
                  />
                </Box>
                <Box>
                  <AllButtonComponent
                    AllButtons="secondaryButton"
                    buttonValue="Send Message"
                    ButtonStyles="contained"
                    disabled={loading}
                    handleSubmit={handleSubmit}
                  />
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#fff",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </motion.div>
            <Divider />
            <Box sx={{ pb: 4 }}>
              <Grid container spacing={2} sx={{ py: 4 }}>
                <Grid className="indianOfficeMap" item xs={12} sm={12} md={9}>
                  <motion.div
                    initial={{
                      opacity: 0,
                      // if odd index card,slide from right instead of left
                      y: index % 1 === 0 ? 50 : -50,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0, // Slide in to its original position
                      transition: {
                        duration: 1, // Animation duration
                        ease: "easeOut",
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <Box>
                      <iframe
                        style={{
                          border: "1px solid #E6E6E6",
                          borderRadius: 2,
                          p: 10,
                          width: "100%",
                          height: "500px",
                        }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.828756030045!2d80.18843631534317!3d12.982802218143906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525dfe85853519%3A0x96da7bdedde2514!2sRajasri%20Systems%20Private%20Limited!5e0!3m2!1sen!2sin!4v1680267595636!5m2!1sen!2sin"
                        loading="lazy"
                      ></iframe>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid
                  className="indianOfficeAddress"
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <motion.div
                    initial={{
                      opacity: 0,
                      // if odd index card,slide from right instead of left
                      y: index % 1 === 0 ? -50 : 50,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0, // Slide in to its original position
                      transition: {
                        duration: 1, // Animation duration
                        ease: "easeOut",
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#0876C1",
                          pb: 4,
                          fontWeight: "500",
                          fontSize: "1.5rem",
                        }}
                      >
                        Rajasri Systems Pvt Ltd
                      </Typography>
                      <Typography sx={{ pb: 2, color: "#808080" }}>
                        #26, First Main Road, Nanganallur Chennai 600061,
                        Tamilnadu India
                      </Typography>
                      <Typography sx={{ pb: 2, color: "#808080" }}>
                        +91-44-22242272
                      </Typography>
                      <Box sx={{ pb: 2 }}>
                        <Link
                          href="mailto:businessdev@rajasri.net"
                          sx={{ color: "#808080", textDecoration: "none" }}
                        >
                          businessdev@rajasri.net
                        </Link>
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  className="japanOfficeAddress"
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <motion.div
                    initial={{
                      opacity: 0,
                      // if odd index card,slide from right instead of left
                      y: index % 1 === 0 ? 50 : -50,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0, // Slide in to its original position
                      transition: {
                        duration: 1, // Animation duration
                        ease: "easeOut",
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#0876C1",
                          pb: 4,
                          fontWeight: "500",
                          fontSize: "1.5rem",
                        }}
                      >
                        Hermits Co., Ltd.
                      </Typography>
                      <Typography sx={{ pb: 2, color: "#808080" }}>
                        #107 Yoshimura Building, 1-13-10 Utsukushigaoka, Aoba,
                        Yokohama, Kanagawa 225-0002, JAPAN
                      </Typography>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid className="japanOfficeMap" item xs={12} sm={12} md={9}>
                  <motion.div
                    initial={{
                      opacity: 0,
                      // if odd index card,slide from right instead of left
                      y: index % 1 === 0 ? -50 : 50,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0, // Slide in to its original position
                      transition: {
                        duration: 1, // Animation duration
                        ease: "easeOut",
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <Box>
                      <iframe
                        style={{
                          border: "1px solid #E6E6E6",
                          borderRadius: 2,
                          width: "100%",
                          height: "500px",
                        }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3244.970215205746!2d139.55047398893453!3d35.57912696499147!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f716c4ae3e35%3A0xfd516c939031e5a4!2zSmFwYW4sIOOAkjIyNS0wMDAyIEthbmFnYXdhLCBZb2tvaGFtYSwgQW9iYSBXYXJkLCBVdHN1a3VzaGlnYW9rYSwgMS1jaMWNbWXiiJLvvJHvvJPiiJIxMA!5e0!3m2!1sen!2sin!4v1715752912588!5m2!1sen!2sin"
                        loading="lazy"
                      ></iframe>
                    </Box>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </section>
    </>
  );
}
